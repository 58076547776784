.typ_oferty_content {
    width: 65%;
    h3 {
        font-size: 22px;
        background-position: top left;
        padding: 13px 35px;
    }
    p {
        padding: 10px 0 0 35px;
        font-size: 18px;
        color: #42413C;
        font-weight: 200;
    }
}

.typ_oferty {
    width: 80%;
    margin: 0 auto 70px auto;
}

.content_middle_text p {
    line-height: 26px;
}

.content_middle_bg {
    margin: 60px 0;
    &, .content_middle_text {
        background: #b0002d;
    }
    .content_middle_text {
        font-size: 17px;
        line-height: 24px;
        width: 364px;
        margin-top: -50px;
        padding-left: 35px;
    }
    .content_middle {
        padding: 108px 210px 108px 210px;
        margin-top: -60px!important;
        h4 {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 10px;
        }
    }
}

.content_middle {
    background-image: url('../images_lp/banner.png');
}

.login-header-wrapper {
    text-align: right;
    margin-bottom: 30px;
    margin-top: -20px;
}
.login-header {
    border-bottom-right-radius: 17px;
    font-size: 23px;
    font-weight: bold;
    color: #fff;
    background-color: #b0002d;
    background-size: 231px 64px;
    background-repeat: no-repeat;
    display: inline-block;
    text-transform: uppercase;
    width: 231px;
    text-align: left;
    padding: 15px 0 16px 0;
    padding-left: 20px;
    width: 950px;
}



.login-form-submit {
    background: none;
    //background-image: url('../images_lp/login-bg-submit.jpg');
    background-color: #ba0234;
    background-repeat: no-repeat;
    border-bottom-right-radius: 17px;
    width: 185px;
    height: 37px;
    border: 0;
    color: #fff;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
}


.form-group {
    text-align: right;
    margin-bottom: 35px;
    label {
        background-color: #478967;
        background-repeat: no-repeat;
        border-bottom-right-radius: 17px;
        margin-right: -10px;
        background-position: center;
        padding: 10px 0;
        width: 76px;
        height: 17px;
        display: inline-block;
        //font-size: 25px;
        margin-top: -7px;
        font-weight: 200;
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        //margin-right: 50px;
        vertical-align: middle;
    }
    input {
        width: 80%;
        //height: 65px;
        //border: 2px solid #959595;
        margin-left: 5px;
        font-size: 20px;
        padding: 5px 10px;
        box-sizing: border-box;
    }
}

.panel-body {
    padding-bottom: 30px;
    //background-image: url('../images_lp/left-skew.jpg');
    //background-size: 177px 278px;
    //background-repeat: no-repeat;
    //background-position: left 115px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
}
